const INFO = {
	main: {
		title: "Onur YERLIKAYA Personal",
		name: "Onur YERLIKAYA",
		email: "yrlkyaonur@gmail.com",
		logo: "../oy.png",
	},

	socials: {
		twitter: "https://twitter.com/onuryrlkya",
		github: "https://github.com/onuryrlkya",
		linkedin: "https://www.linkedin.com/in/onuryerlikaya06/",
		instagram: "https://www.instagram.com/onuryerlikayaa_/",
		stackoverflow: "https://stackoverflow.com/onur-yerlikaya",

	},

	homepage: {
		title: "Software engineer, student and ever-evolving coder.",
		description:
			"I am a third year Software Engineering student at Ostim Technical University and have a keen interest in web development. I have gained proficiency in HTML, CSS, JavaScript, React.js and various front-end libraries. I also have experience in back-end technologies such as Java, MySQL and PostgreSQL. In addition, I am continuously improving my knowledge on the Spring Boot framework. Currently, I am involved in a COBOL conversion project that allows me to dive deeper into the intricacies of the software development process. With each project, I look for opportunities to further my knowledge and continuously improve myself. I have a strong passion for learning and problem solving. I am open to teamwork and collaboration and aim to achieve the highest quality results in every task I undertake.",
	},

	about: {
		title: "I am Onur. I live in Ankara, coding the future.",
		description:
			"Hello, I'm Onur, a passionate software engineering student from Ankara. Since the beginning of my educational journey and career, I have relentlessly pursued every opportunity to enhance my skills and adapt to the ever-evolving demands of the industry. Initially, I focused on honing my front-end skills, gaining proficiency in HTML, CSS, JavaScript, React.js, and React Native. However, as I progressed, I realized the importance of becoming versatile in both front-end and back-end development. Currently, I am deepening my knowledge in Java and the Java Spring framework, working on self-initiated projects that allow me to grow as a full-stack developer. In addition to my individual efforts, I have had the privilege of participating in various projects at the company I work for. Currently, I am involved in a digital transformation project centered around COBOL. This experience has allowed me to further expand my knowledge and contribute meaningfully to the team. I am always eager to take on new challenges, collaborate with like-minded individuals, and continually push myself towards professional and personal growth. I am open to exciting new opportunities where I can apply my skills, and I remain committed to lifelong learning and improvement.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "PDF Grammar Check Project Very Soon",
			description:
				"This project provides a platform for real-time grammar checks on uploaded PDF documents. It detects errors, generates a report, and allows users to download the corrected PDF. Built with Java Spring and Apache PDFBox, the platform features a modern, minimalist interface. It will be released as closed-source soon.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/java/java.png",
			linkText: "View Project",
			link: "https://github.com/onuryrlkya",
		},

		{
			title: "YemekYolu E-Commerse",
			description:
				"A kind of food ordering platform that lists and searches according to price and quality by pulling data from yelp api written with React Native Mobile App.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/onuryrlkya",
		},

		{
			title: "Car Service Project",
			description:
				"",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/onuryrlkya",
		},

		{
			title: "Book Store",
			description:
				"A small application I made to put what I did in Spring into practice.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/java/java.png",
			linkText: "View Project",
			link: "https://github.com/onuryrlkya",
		},

		{
			title: "Meal Decision App",
			description:
				"Due to the indecisiveness I experienced at meal times in daily life, I thought of developing such an application. By using APIs according to each food category, it offers you suggestions and helps you to eliminate your indecision by showing you random food in the type you choose.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/onuryrlkya",
		},
	],
};

export default INFO;
