const SEO = [
	{
		page: "home",
		description:
			"Onur Yerlikaya, a dedicated software engineer from Ankara, is enhancing his skills in software development and contributing to impactful digital transformation projects.",
		keywords: [
			"Onur",
			"Yerlikaya",
			"onur yerlikaya",
			"yazılım mühendisliği",
			"Java",
			"Spring Boot",
			"React",
			"COBOL",
			"onuryrlkya",
			"Ankara yazılım geliştirici"

		],
	},

	{
		page: "about",
		description:
			"Onur Yerlikaya, a dedicated software engineer from Ankara, is enhancing his skills in software development and contributing to impactful digital transformation projects.",
		keywords: [
			"Onur",
			"Yerlikaya",
			"onur yerlikaya",
			"yazılım mühendisliği",
			"Java",
			"Spring Boot",
			"onuryrlkya",
			"React",
			"COBOL",
			"Ankara yazılım geliştirici"
		],
	},

	{
		page: "projects",
		description:
			"Onur Yerlikaya, a dedicated software engineer from Ankara, is enhancing his skills in software development and contributing to impactful digital transformation projects.",
		keywords: [
			"Onur",
			"Yerlikaya",
			"onur yerlikaya",
			"yazılım mühendisliği",
			"Java",
			"Spring Boot",
			"React",
			"onuryrlkya",
			"COBOL",
			"Ankara yazılım geliştirici"
		],
	},

	{
		page: "contact",
		description:
			"Onur Yerlikaya, a dedicated software engineer from Ankara, is enhancing his skills in software development and contributing to impactful digital transformation projects.",
		keywords: [
			"Onur",
			"Yerlikaya",
			"onur yerlikaya",
			"yazılım mühendisliği",
			"Java",
			"Spring Boot",
			"React",
			"onuryrlkya",
			"COBOL",
			"Ankara yazılım geliştirici"
		],
	},
];

export default SEO;
