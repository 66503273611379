import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./sesasis.png"
								alt="sesasis"
								className="work-image"
							/>
							<div className="work-title">SESASIS Bilgi Teknolojileri</div>
							<div className="work-subtitle">
								Software Developer
							</div>
							<div className="work-duration">Nov 2023 - Currently</div>
						</div>

						<div className="work">
							<img
								src="./ostim.png"
								alt="ostim"
								className="work-image"
							/>
							<div className="work-title">Ostim Technical University</div>
							<div className="work-subtitle">
								Frontend Developer
							</div>
							<div className="work-duration">Dec 2023 - Jun 2024 </div>
						</div>

						<div className="work">
							<img
								src="./sesasis.png"
								alt="sesasis"
								className="work-image"
							/>
							<div className="work-title">SESASIS Bilgi Teknolojileri</div>
							<div className="work-subtitle">
								FullStack Developer Intern
							</div>
							<div className="work-duration">Jun 2023 - Nov 2023 </div>
						</div>
						<div className="work">
							<img
								src="./ostim.png"
								alt="ostim"
								className="work-image"
							/>
							<div className="work-title">Ostim Technical University</div>
							<div className="work-subtitle">
								Software Engineering Student
							</div>
							<div className="work-duration">2022 - 2026</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
